export enum TicketStatus {
    Open = 'Open',
    WorkInProgress = 'Work in Progress',
    RequestForApproval = 'Request for Approval',
    RequestApproved = 'Request Approved',
    Hold = 'Hold',
    Resolved = 'Resolved',
    ReOpen = 'Reopen',
    Closed = 'Closed',
    RevisedQuotation ='Revised Quotation'
}
