import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Roles, TicketStatus } from 'src/app/_enums';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'ticket-log-timeline',
  templateUrl: './ticket-log-timeline.component.html',
  styleUrls: ['./ticket-log-timeline.component.scss']
})
export class TicketLogTimelineComponent implements OnInit {
  public logHeight: number = 130;
  public lineWidth: number = 5;
  public circleRadious: number = 7.5;
  public cardWidth: number = 270;
  public ticketStatus = TicketStatus;
  public environment= environment;
  @Input() ticketLog: any[] = [];
  @Input() serviceOrderNumber: any;
  @Input() unitSerialNoRefId:any;
  @Input() callCategoryName:any;
  @Input() ticketDescription:any;
  @Input() ticketUpdatedDate:any;
  constructor() { }

  ngOnInit(): void {
  }

}
