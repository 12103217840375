<div class="cd-timeline-title" [ngClass.gt-sm]="'gt-sm'">
  <div>Ticket Log</div>
</div>
<div class="cd-timeline" [ngClass.gt-sm]="'gt-sm'">
  <div class="cd-timeline-block" *ngFor="let log of ticketLog; let i=index; let o=odd">
    <div class="cd-timeline-circle"></div>
    <div class="cd-timeline-content">
      <div class="line-item">
        <div class="status">{{log?.logTicketStatus}}</div>
      </div>
      <div class="line-item">
          <div class="comment" *ngIf="log?.logTicketStatus !== ticketStatus.Open">SO NO: {{log?.logServiceOrderNumber || 'No Comment'}}</div>
        <div class="comment" *ngIf="log?.logTicketStatus === ticketStatus.Open">
            Unit Serial No: {{ unitSerialNoRefId || 'No Comment'}}</div>
        <div class="comment" *ngIf="log?.logTicketStatus === ticketStatus.Open">
            Call Category: {{  callCategoryName  || 'No Comment'}}</div>
          <div class="comment" *ngIf="log?.logTicketStatus === ticketStatus.Open">
            Description: {{ ticketDescription || '-'}}</div>
          <div class="comment" *ngIf="log?.logTicketStatus === ticketStatus.Open">
            Images:</div>
          <div class="comment" *ngIf="log?.logTicketStatus === ticketStatus.Open">
            ticket date and time:</div>
        <div class="comment" [title]="log?.logRemarks" *ngIf="log?.logTicketStatus !== ticketStatus.Open">
            <span style="color:blue;font-weight:bold;">Engineer Name :{{log?.engName || 'No Comment'}}</span></div>
        <div class="comment" [title]="log?.logRemarks" *ngIf="log?.logTicketStatus !== ticketStatus.Open">Visting Date and time 
:{{ticketUpdatedDate || 'No Comment'}}</div>
        <div class="comment" [title]="log?.logRemarks" *ngIf="log?.logTicketStatus !== ticketStatus.Open">Engineer Observation
:{{log?.logEngineerObservation || 'No Comment'}}</div>
        <div class="comment" [title]="log?.logRemarks" *ngIf="log?.logTicketStatus !== ticketStatus.Open">Corrective Action: {{log?. logCorrectiveAction || 'No Comment'}}</div>
        <div class="comment" [title]="log?.logRemarks" *ngIf="log?.logTicketStatus !== ticketStatus.Open">Remarks: {{log?. logRemarks  || 'No Comment'}}</div>
      </div>
      <div class="line-item" fxLayout="row-reverse" fxLayoutAlign="space-between start" fxLayoutGap="5px">
        <div class="timestamp">{{log?.logTicketCreatedDate | date: 'dd/MM/yyyy HH:mm'}}</div>
        <div class="attachment" *ngIf="log?.ticImageUrl">
          <a [href]="environment.apiUrl+log?.ticImageUrl" target="_blank" rel="noopener noreferrer">Download Attachment</a>
        </div>
      </div>
    </div>
  </div>
