export enum Roles {
  Admin = '1',
  FujiBranch = '2',
  KeyAccount = '3',
  Zonal = '4',
  Regional = '5',
  IBase = '6',
  ServiceEngineer = '7',
  ExecutiveManager = '8',
  ExecutiveAgent = '9',
}
